img {
    object-fit: contain;
}

.spacer {
    flex-grow: 1;
}

body {
    background-color: #f4f6f8;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    /* font-weight: 500; */
}

.input-outline {
    border: 1px solid;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
}

.input-outline:hover {
    border-color: black;
}

.text-green td {
    color: limegreen !important
}

.text-green {
    color: limegreen !important
}

.text-red {
    color: red !important
}

.play_game_btn{
    background-color: #0080a0;
}

.play_game_btn:hover{
    background-color: #00485a;
}


a {
    color: inherit;
    /* blue colors for links too */
    text-decoration: inherit;
    /* no underline */
}

.aspect-ratio-wrapper {
    width: 100%;
    position: relative;
}

.aspect-ratio-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}